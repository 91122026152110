@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

.popular-movies{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

img{
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

h2 {
  font-family: 'Fjalla One', sans-serif;
  color: #c7493a;
  font-size: 0.9rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
h3 {
  font-family: 'Oleo Script', cursive;  
  color: #386e6e;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}


.btn {
  margin-right: 2rem;
  margin-top: 2rem;
  min-width: 5rem;
  border: 5em;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  -webkit-transform: translate(0);
  transform: translate(0);
  background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  padding: 0.7em 2em;
  border-radius: 65px;
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
  -webkit-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  color: white;
}

.btn .text {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #4568dc, #b06ab3);
}

.btn:after {
  content: "";
  border-radius: 18px;
  position: absolute;
  margin: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: #0e0e10;
}

.btn:hover {
  background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
  box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
}

.btn:hover .text {
  background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
}